import DiscoVirtualServices from '@/assets/js/services/DiscoVirtualServices'

export default {
    namespaced: true,

    state: {
        arquivos_disco_virtual: null,
        arquivos_loading: false
    },

    getters: {
        arquivosDiscoVirtual(state) {
            return state.arquivos_disco_virtual
        },
        arquivosDiscoVirtualLoading(state) {
            return state.arquivos_loading
        }
    },

    mutations: {
        SET_DISCO_VIRTUAL_ARQUIVOS(state, arquivos) {
            state.arquivos_disco_virtual = arquivos
            state.arquivos_loading = false
        },
        SET_ARQUIVOS_LOADING(state, isLoading) {
            state.arquivos_loading = isLoading
        }
    },

    actions: {
        async buscaArquivosDiscoVirtual({ commit }) {
            try {                
                commit('SET_ARQUIVOS_LOADING', true)
                const arquivos = await DiscoVirtualServices.getArquivosDiscoVirtual()
                commit('SET_DISCO_VIRTUAL_ARQUIVOS', arquivos)
            } catch (e) {
                console.log(e)
            }
            commit('SET_ARQUIVOS_LOADING', false)
        },
        async uploadArquivoDiscoVirtual({ commit, dispatch }, formData) {
            try {
                commit('SET_ARQUIVOS_LOADING', true)
                await DiscoVirtualServices.postArquivoDiscoVirtual(formData)
                setTimeout(() => { dispatch('buscaArquivosDiscoVirtual') }, 1000)
            } catch (e) {
                console.log(e)
            }
            commit('SET_ARQUIVOS_LOADING', false)
        },
        async deleteAll({ commit, dispatch }) {
            try {
                commit('SET_ARQUIVOS_LOADING', true)
                await DiscoVirtualServices.deleteAll()
                setTimeout(() => { dispatch('buscaArquivosDiscoVirtual') }, 1000)
            } catch (e) {
                console.log(e)
            }
            commit('SET_ARQUIVOS_LOADING', false)
        },
        async deletarArquivoDiscoVirtual({ commit, dispatch }, pathImg) {
            try {
                commit('SET_ARQUIVOS_LOADING', true)
                await DiscoVirtualServices.deleteArquivoDiscoVirtual(pathImg)
                setTimeout(() => { dispatch('buscaArquivosDiscoVirtual') }, 1000)
            } catch (e) {
                console.log(e)
            }
            commit('SET_ARQUIVOS_LOADING', false)
        },
        async editarArquivosDiscoVirtual({ commit, dispatch }, objFile) {
            try {
                commit('SET_ARQUIVOS_LOADING', true)
                await DiscoVirtualServices.editarArquivo(objFile)
                setTimeout(() => { dispatch('buscaArquivosDiscoVirtual') }, 1000)
            } catch (e) {
                console.log(e)
            }
            commit('SET_ARQUIVOS_LOADING', false)
        }
    },
}
