import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const buscarPausas = async () => {
    try {
        const response = await axios.get(`${URL_API}/ConfiguracoesPausas`);
        return response ? response.data : null
    } catch (err) {
        LogServices.logErro(err, 'BuscarPausas');
        throw (err);
    }
}

const buscarPausasComFiltro = async (dados) => {
    try {
        const response = await axios.get(`${URL_API}/ConfiguracoesPausas/buscarPausasComFiltro`, {
            params: {
                'Nome': dados.Nome,
                'Offset': dados.Offset
            }
        });
        return response ? response.data : null
    } catch (err) {
        LogServices.logErro(err, 'BuscarPausas');
        throw (err);
    }
}

const excluirPausa = async (id) => {
    try {
        await axios.post(`${URL_API}/ConfiguracoesPausas/excluirPausa/${id}`);
    } catch (e) {
        LogServices.logErro(e, 'ExcluirPausa');
        throw (e);
    }
}

const salvarOuEditarPausa = async (dados) => {
    try {
        const response = await axios.post(`${URL_API}/ConfiguracoesPausas`, dados);
        return response ? response.data : null
    } catch (err) {
        LogServices.logErro(err, 'salvarPausa');
        throw (err);
    }
}

export default {
    buscarPausas,
    buscarPausasComFiltro,
    salvarOuEditarPausa,
    excluirPausa
}