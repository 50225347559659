import axios from 'axios'
const URL_API = '/api'

// * EMPRESAS *

const getEmpresas = async () => {
    try {
        const response = await axios.get(`${URL_API}/licenca/empresas`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getEmpresas', e)
        throw (e)
    }
}

const postEmpresa = async (empresa) => {
    try {
        delete empresa.Id
        const response = await axios.post(`${URL_API}/licenca/empresa`, empresa)
        return response ? response.data : null
    } catch (e) {
        console.log('erro postEmpresa', e)
        throw (e)
    }
}

const putEmpresa = async (empresa) => {
    try {
        const response = await axios.put(`${URL_API}/licenca/empresa`, empresa)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putEmpresa', e)
        throw (e)
    }
}

const deleteEmpresa = async (id) => {
    try {
        const response = await axios.delete(`${URL_API}/licenca/empresa/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removerEmpresa', e)
        throw (e)
    }
}

const putEmpresaStatusAtividade = async (id) => {
    try {
        const response = await axios.put(`${URL_API}/licenca/empresa/ativa-desativa/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putEmpresaStatusAtividade', e)
        throw (e)
    }
}

const getEmpresaModulosHabilitados = async () => {
    try {
        const response = await axios.get(`${URL_API}/licenca/empresa/modulos-habilitados`)
        return response ? response.data : null
    } catch (e) {
        console.log('getEmpresaModulosHabilitados', e)
        throw (e)
    }
}

// * LICENÇAS *

const getLicencas = async () => {
    try {
        const response = await axios.get(`${URL_API}/licenca/licencas`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getLicencas', e)
        throw (e)
    }
}

const postLicenca = async (licenca) => {
    try {
        const response = await axios.post(`${URL_API}/licenca`, licenca)
        return response ? response.data : null
    } catch (e) {
        console.log('erro postLicenca', e)
        throw (e)
    }
}

const putLicenca = async (licencaEmpresa) => {
    try {
        const response = await axios.put(`${URL_API}/licenca/atualizar`, licencaEmpresa)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putLicença', e)
        throw (e)
    }
}

export default {
    getEmpresas,
    postEmpresa,
    putEmpresa,
    deleteEmpresa,
    putEmpresaStatusAtividade,
    getLicencas,
    postLicenca,
    putLicenca,
    getEmpresaModulosHabilitados
}