let usuarioLogado = {};
const refreshUsuarioLogado = () => {
    
    usuarioLogado = localStorage.login == null ? {} : JSON.parse(localStorage.login);
};
refreshUsuarioLogado();

export {
    usuarioLogado as default,
    refreshUsuarioLogado
};