import axios from 'axios'
const URL_API = '/api'

const getUsuarios = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuario`)
        return response ? response.data.Usuarios : null
    } catch (e) {
        console.log('erro getUsuarios', e)
        throw (e)
    }
}

const postUsuario = async (usuario) => {
    try {
        delete usuario.Id;
        const response = await axios.post(`${URL_API}/usuario`, usuario)
        return response ? response.data : null
    } catch (e) {
        console.log('erro postUsuario', e)
        throw (e)
    }
}

const putUsuario = async (usuario) => {
    try {
        const response = await axios.put(`${URL_API}/usuario`, usuario)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putUsuario', e)
        throw (e)
    }
}

const removeUsuario = async (id) => {
    try {
        const response = await axios.delete(`${URL_API}/usuario/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removeUsuario', e)
        throw (e)
    }
}

const resetarSenha = async (id) => {
    try {
        console.log(id)
        const response = await axios.post(`${URL_API}/Usuario/resetarSenha/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro resetarSenha', e)
        throw (e)
    }
}

const alterarAssinatura = async (usuario) => {
    try {
        const response = await axios.put(`${URL_API}/usuario/assinatura`, usuario)
        return response ? response.data : null
    } catch (e) {
        console.log('erro atualizarAssinatura', e)
        throw (e)
    }
}

const getUsuariosStatus = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuario/status`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getUsuarioStatus', e)
        throw (e)
    }
}

const getIsOnline = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuario/isOnline`)
        if (!response.data) {
            localStorage.removeItem('login');
            delete axios.defaults.headers.common['Authorization'];
            window.location.href = window.location.origin + '/login'
        }
        return response ? response.data : null
    } catch (e) {
        console.log('erro getIsOnline', e)
        throw (e)
    }
}

const getUsuariosPerfis = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuario/perfis`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getUsuarioPerfis', e)
        throw (e)
    }
}

const alterarDisponibilidadeAtendimento = async () => {
    try {
        const response = await axios.put(`${URL_API}/usuario/atendimento-disponivel`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro alterarDisponibilidadeAtendimento', e)
        throw (e)
    }
}

export default {
    getUsuarios,
    postUsuario,
    putUsuario,
    removeUsuario,
    resetarSenha,
    alterarAssinatura,
    alterarDisponibilidadeAtendimento,
    getUsuariosStatus,
    getUsuariosPerfis,
    getIsOnline,
}