import ModulosServices from '@/assets/js/services/ModulosServices'


export default {
    namespaced: true,

    state: {
        modulos_integracoes: null,
        
    },
    getters: {
       modulosItegracoes(state) {
            return state.modulos_integracoes;
        }
    },

    mutations: {
        SET_MODULOS(state, modulos) {
            state.modulos_integracoes = modulos
        }
    },

    actions: {
        async buscaModulos({ commit }) {
            try {  
                const modulos = await ModulosServices.ModulosDisponiveis()
                commit('SET_MODULOS', modulos)
            } catch (e) {
                console.log(e)
            }
        },
    },
}
