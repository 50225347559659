import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const buscaConfiguracoesModulos = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Modulos`)
        return data?.map(modulo => ({...modulo, Parametros: JSON.parse(modulo.Parametros)}))
    } catch (e) {
        LogServices.logErro(e, 'buscaConfiguracoesModulos')
        throw(e)
    }
}
const ModulosDisponiveis = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Modulos/ModulosDisponiveis`)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'ModulosDisponiveis')
        throw(e)
    }
}
const consultaNuvem = async (dados) => {
    try {
        const { data } = await axios.post(`${URL_API}/Modulos/consultaNuvem`, dados)
        
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'consultaNuvem')
        throw(e)
    }
}
const rastreioCorreios = async (codigo) => {
   
    try {
        const { data } = await axios.get(`${URL_API}/Modulos/rastreioCorreios/`+ codigo)
        
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'rastreioCorreios')
        throw(e)
    }
}
const carregarParametrosModulos = async (origem, dado) => {
    try {
        const { data } = await axios.get(`${URL_API}/Modulos/carregarParametrosModulos/`+ origem +"/"+dado)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'ModulosDisponiveis')
        throw (e)
    }
}
const GerarModulo = async (dado) => {
    try {
        const { data } = await axios.post(`${URL_API}/Modulos/GerarModulo/`+ dado)
        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'GerarMoodulo')
        throw(e)
    }
}

const salvarConfiguracaoModulo = async (configuracao) => {
    try {
        const configuracaoComParametrosString = {
            ...configuracao,
            Parametros: JSON.stringify(configuracao.Parametros)
        }
        
        const { data } = await axios.post(`${URL_API}/Modulos`, configuracaoComParametrosString)
        return data?? null
    } catch (e) {
        LogServices.logErro(e, 'salvarConfiguracaoModulo')
        throw(e)
    }
}
const consultaBling = async (dados) => {
  
    try {
        const { data } = await axios.post(`${URL_API}/Modulos/consultaBling`, dados)

        return data ?? null
    } catch (e) {
        LogServices.logErro(e, 'consultaBling')
        throw (e)
    }
}
export default {
    buscaConfiguracoesModulos,
    salvarConfiguracaoModulo,
    ModulosDisponiveis,
    GerarModulo,
    carregarParametrosModulos,
    consultaNuvem,
    rastreioCorreios,
    consultaBling
}
