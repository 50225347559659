const logErro = (erro, origem) => {
    if(!erro) {
        return
    }

    // Erro do JS
    if(erro.message) {
        console.log(erro.message)
    }

    // Nao eh erro no axios
    if(!erro.response) {
        console.log(erro)
        return
    }

    // Erros do axios
    if(erro.response.status >= 500) {
        console.log(`Requisicao ${origem} retornou um erro do servidor`)
        return
    }

    if(erro.response.status == 401) {
        console.log(`Nao autorizado ${origem}`)
        return
    }

    if(erro.response.status >= 400 && erro.response.status <= 499) {
        if(erro.response.data) {
            console.log(`Erro de validação ${origem} retornou status ${erro.response.status} detalhes: `, erro.response.data)
            return
        }
        console.log(`Requisicao ${origem} retornou status ${erro.response.status}`)
        return
    }
    
    console.log(`Erro desconhecido em ${origem}, `, erro.response)
}

export default {
    logErro
}
