import axios from 'axios'
const URL_API = '/api'

const getSetores = async () => {
    try {
        const response = await axios.get(`${URL_API}/setor`)
    
        return response? response.data: null
    } catch (e) {
        console.log('erro getSetores', e)
        throw(e)
    }
}

const getListaBasicaSetores = async () => {
    try {
        const response = await axios.get(`${URL_API}/setor/lista-basica`);
        return response ? response.data : null;
    } catch (e) {
        console.log('erro getListaBasicaSetores', e);
        throw (e);
    }
}

const adicionar = async (setor) => {
    try {
        delete setor.Id;
        delete setor.EmpresaId;
        const response = await axios.post(`${URL_API}/setor`, setor)
        return response ? response.data : null
    } catch (e) {
        console.log('erro adicionarSetor', e)
        throw (e)
    }
}

const editar = async (setor) => {
    try {
        const response = await axios.put(`${URL_API}/setor`, setor)
        return response ? response.data : null
    } catch (e) {
        console.log('erro editarSetor', e)
        throw (e)
    }
}

const remover = async (data) => {
    try {
        const response = await axios.delete(`${URL_API}/setor/DeletarSetor`, {data: data})
        return response ? response.data : null
    } catch (e) {
        console.log('erro removerSetor', e)
        throw (e)
    }
}

const getSetoresEmail = async () => {
    try {
        const response = await axios.get(`${URL_API}/setor/setores-email`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getSetoresEmail', e)
        throw (e)
    }
}

export default {
    getSetores,
    getListaBasicaSetores,
    adicionar,
    editar,
    remover,
    getSetoresEmail,
}
