import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const verificarUsuarioPausado = async (usuarioId) => {
    try {
        const isPausado = await axios.get(`${URL_API}/UsuariosPausados/verificarUsuarioPausado`);
        return isPausado.data;
    } catch (e) {
        LogServices.logErro(e, 'verificaUsuarioPausado');
        throw (e);
    }
}

const pausar = async (dadosUsuarioPausado) => {
    try {
        await axios.post(`${URL_API}/UsuariosPausados/pausar`, dadosUsuarioPausado);
    } catch (e) {
        LogServices.logErro(e, 'pausar');
        throw (e);
    }
}

const despausar = async (dadosUsuarioDespausado) => {
    try {
        const { data } = await axios.post(`${URL_API}/UsuariosPausados/despausar`, dadosUsuarioDespausado);
        return data;
    } catch (e) {
        LogServices.logErro(e, 'despausar');
        throw (e);
    }
}

export default {
    verificarUsuarioPausado,
    pausar,
    despausar
}