import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'
const URL_API = '/api'

const getArquivosDiscoVirtual = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/DiscoVirtual`)
        return data
    } catch (e) {
        console.log('erro getDiscoVirtualArquivos', e)
        throw (e)
    }
}

const postArquivoDiscoVirtual = async (formData) => {
    try {
        await axios.post(`${URL_API}/DiscoVirtual/SalvarArquivo`, formData)
    } catch (e) {
        console.log('erro postArquivoDiscoVirtual', e)
        throw (e)
    }
}

const deleteArquivoDiscoVirtual = async (pathImg) => {
    try {
        var imgComplete = {
            pathImg: pathImg
        }
        await axios.post(`${URL_API}/DiscoVirtual/DeletarArquivo`, imgComplete)
    } catch (e) {
        console.log('erro deleteArquivoDiscoVirtual', e)
        throw (e)
    }
}

const deleteAll = async () => {
    try {
        await axios.post(`${URL_API}/DiscoVirtual/DeleteTudo`)

    } catch (e) {
        LogServices.logErro(e, 'deleteAll')
        throw (e)
    }
}

const editarArquivo = async (objFile) => {
    try {

        let formData = new FormData()
        formData.append('file', objFile.file)

        const { data } = await axios.post(`${URL_API}/DiscoVirtual/EditarArquivo/${objFile.nomeFile}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        return data
    } catch (e) {
        LogServices.logErro(e, 'editarArquivo')
        throw (e)
    }
}
const salvarArquivoDiscoVirtual = async (atendimentoId, files = [], origem = "normal", controleOrigem) => {
    try {
        const formData = new FormData();
        files.forEach(obj => {
            formData.append('files', obj.file)
        })


        const headerFormData = { headers: { "Content-Type": "multipart/form-data" } };

        let url = `${URL_API}/discovirtual/SalvarArquivo/` + atendimentoId + "/" + controleOrigem + "/" + origem;

        const { data } = await axios.post(url, formData, headerFormData);
        return data;
    } catch (e) {
        console.log('erro salvarArquivoDiscoVirtual', e)
        throw (e)
    }
}
const carregaImagensAtendimentoDV = async (atendimentoId) => {
    try {
        const { data } = await axios.get(`${URL_API}/discovirtual/carregaImagensAtendimentoDV/` + atendimentoId + "");

        return data
    } catch (e) {
        LogServices.logErro(e, 'carregaImagensAtendimentoDV')
        throw (e)
    }
}

const uploadFiles = async (files = []) => {
    const formData = new FormData();
    files.forEach(file => {
        formData.append('files', file)
    })

    try {
        const headerFormData = { headers: { "Content-Type": "multipart/form-data" } };
        const { data } = await axios.post(`${URL_API}/discovirtual/files`, formData, headerFormData);
        return data
    } catch (e) {
        LogServices.logErro(e, 'uploadFiles')
        throw (e)
    }
}

const removeFile = async (fileName) => {
    try {
        const { data } = await axios.delete(`${URL_API}/discovirtual/file/${fileName}`);
        return data
    } catch (e) {
        LogServices.logErro(e, 'removeFile')
        throw (e)
    }
}
const carregaArquivosCP = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/discoVirtual/carregaArquivosCP`)

        return data
    } catch (e) {
        $("#caixa_carregamento").hide()
        console.log('erro carregar categoria de produtos', e)
        throw (e)
    }
}

export default {
    getArquivosDiscoVirtual,
    postArquivoDiscoVirtual,
    deleteArquivoDiscoVirtual,
    editarArquivo,
    deleteAll,
    salvarArquivoDiscoVirtual,
    carregaImagensAtendimentoDV,
    uploadFiles,
    removeFile,
    carregaArquivosCP
}
