import axios from 'axios'
const URL_API = '/api'

const getUsuarioSetores = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuariosetor`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getUsuarioSetores', e)
        throw (e)
    }
}

const postUsuarioSetor = async (usuarioSetor) => {
    try {
        delete usuarioSetor.Id;
        const response = await axios.post(`${URL_API}/usuariosetor`, usuarioSetor)
        return response ? response.data : null
    } catch (e) {
        console.log('erro postUsuarioSetor', e)
        throw (e)
    }
}

const putUsuarioSetor = async (usuarioSetor) => {
    try {
        const response = await axios.put(`${URL_API}/usuariosetor`, usuarioSetor)
        return response ? response.data : null
    } catch (e) {
        console.log('erro putUsuarioSetor', e)
        throw (e)
    }
}

const removeUsuarioSetor = async (id) => {
    try {
        const response = await axios.delete(`${URL_API}/usuariosetor/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removeUsuarioSetor', e)
        throw (e)
    }
}

const getPrioridades = async () => {
    try {
        const response = await axios.get(`${URL_API}/usuariosetor/prioridades`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getPrioridades', e)
        throw (e)
    }
}

export default {
    getUsuarioSetores,
    postUsuarioSetor,
    putUsuarioSetor,
    removeUsuarioSetor,
    getPrioridades
}