import axios from 'axios'
import LogServices from '@/assets/js/services/LogServices'

const URL_API = '/api'

const BuscarGrupos = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/SharkWhats/BuscarGrupos`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'BuscarGrupos')
        throw(e)
    }
}

const GrupoSelecionado = async (idGrupo) => {
    try {
        const { data } = await axios.get(`${URL_API}/SharkWhats/BuscarGrupoSelecionado/${idGrupo}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'GrupoSelecionado')
        throw(e)
    }
}

const BuscaConversa = async (idGrupo) => {
    try {
        const { data } = await axios.get(`${URL_API}/SharkWhats/BuscarConversas/${idGrupo}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'BuscaConversa')
        throw(e)
    }
}

const BuscaIntegrantes = async (idGrupo) => {
    try {
        const { data } = await axios.get(`${URL_API}/SharkWhats/BuscaIntegrantes/${idGrupo}`)
        return data
    } catch (e) {
        LogServices.logErro(e, 'BuscaIntegrantes')
        throw(e)
    }
}

const RemoverGrupo = async (id) => {
    try {
        await axios.delete(`${URL_API}/SharkWhats/RemoverGrupo/${id}`);
    } catch (e) {
        LogServices.logErro(e, 'RemoverGrupo');
        throw (e);
    }
}

export default {
    BuscarGrupos,
    GrupoSelecionado,
    BuscaConversa,
    BuscaIntegrantes,
    RemoverGrupo
}
