import SalaGruposServices from '@/assets/js/services/SalaGruposServices.js'

export default {
    namespaced: true,

    state: {
        lista_grupos: [],
        lista_integrantes: [],
        lista_conversas: [],

        grupo_selecionado: null,

        loading: false,
        grupo_selecionado_loading: false,
        grupo_conversas_loading: false,
        grupo_integrantes_loading: false

    },

    getters: {
        listaGrupos(state) {
            return state.lista_grupos
        },
        listaIntegrantes(state) {
            return state.lista_integrantes
        },
        listaConversas(state) {
            return state.lista_conversas
        },
        grupoSelecionado(state) {
            return state.grupo_selecionado
        },
        loading(state) {
            return state.loading
        },
        grupoSelecionadoLoading(state) {
            return state.grupo_selecionado_loading
        },
        grupoConversasLoading(state) {
            return state.grupo_conversas_loading
        },
        grupoIntegranteLoading(state) {
            return state.grupo_integrantes_loading
        }
    },

    mutations: {
        SET_GRUPOS(state, payload) {
            state.lista_grupos = payload
        },
        SET_INTEGRANTES(state, payload) {
            state.lista_integrantes = payload
            state.grupo_integrantes_loading = false
        },
        SET_CONVERSAS(state, payload) {
            state.lista_conversas = payload
            state.grupo_conversas_loading = false
        },
        SET_GRUPO_SELECIONADO(state, payload) {
            state.grupo_selecionado = payload
        },
        SET_LOADING(state, payload) {
            state.loading = payload
        },
        SET_SELECIONADO_LOADING(state, payload) {
            state.grupo_selecionado_loading = payload
        },
        SET_CONVERSAS_LOADING(state, payload) {
            state.grupo_conversas_loading = payload
        },
        SET_INTEGRANTES_LOADING(state, payload) {
            state.grupo_integrantes_loading = payload
        }
    },

    actions: {
        async buscaGrupos({ commit }) {
            try {
                commit('SET_LOADING', true)
                var response = await SalaGruposServices.BuscarGrupos()
                commit('SET_GRUPOS', response)
            } catch (e) {
                console.log(e)
                return
            }
            commit('SET_LOADING', false)
            return response
        },

        async grupoSelecionado({ commit, dispatch }, idGrupo) {
            try {
                var grupoSelecionado = await SalaGruposServices.GrupoSelecionado(idGrupo)

                if (!grupoSelecionado) {
                    dispatch('deselecionaGrupo')
                    return
                }

                commit('SET_GRUPO_SELECIONADO', { ...grupoSelecionado, Id: grupoSelecionado.Id })
                dispatch('buscaConversa')
                dispatch('buscaIntegrantes')

            } catch (e) {
                console.log(e)
                dispatch('deselecionaGrupo')
            }
        },

        deselecionaGrupo({ commit, dispatch }) {
            commit('SET_GRUPO_SELECIONADO', null)
            commit('SET_INTEGRANTES', null)
        },

        async buscaConversa({ state, commit }, { setLoading = true } = {}) {
            const idGrupo = state.grupo_selecionado.Id

            if (setLoading) {
                commit('SET_CONVERSAS_LOADING', true)
            }

            try {
                var conversasGrupo = await SalaGruposServices.BuscaConversa(idGrupo)

                commit('SET_CONVERSAS', conversasGrupo)
            } catch (e) {
                console.log('Erro na Busca de Conversas', e)
                commit('SET_CONVERSAS', null)
            }
        },

        async buscaIntegrantes({ state, commit }, { setLoading = true } = {}) {
            const idGrupo = state.grupo_selecionado.Id

            if (setLoading) {
                commit('SET_INTEGRANTES_LOADING', true)
            }

            try {
                var IntegrantesGrupo = await SalaGruposServices.BuscaIntegrantes(idGrupo)

                commit('SET_INTEGRANTES', IntegrantesGrupo)
            } catch (e) {
                console.log('Erro na Busca de integrantes', e)
                commit('SET_INTEGRANTES', null)
            }
        },

        async removerGrupo({ commit, dispatch }, id) {
            commit('SET_LOADING', true)
            await SalaGruposServices.RemoverGrupo(id);
            dispatch('buscaGrupos');
            commit('SET_LOADING', false)
        }
    },
}
