import AtendimentoServices from '@/assets/js/services/AtendimentoServices'
import AtendimentoEmailServices from '@/assets/js/services/AtendimentoEmailServices'
import ModuloEmailServices from '@/assets/js/services/ModuloEmailServices'

export default {
    namespaced: true,

    state: {
        anexos_email: null,
        anexos_para_enviar: null,
        mensagens_predefinidas: null,
        mensagem_predefinida_selecionada: null,
        assunto: '',
        email_para: '',
        email_cc: '',
        email_anexo: ''
    },

    getters: {
        anexosEmail(state) {
            return state.anexos_email
        },
        anexosParaEnviar(state) {
            return state.anexos_para_enviar
        },
        mensagensPredefinidas(state) {
            return state.mensagens_predefinidas
        },
        mensagemPredefinidaSelecionada(state) {
            return state.mensagem_predefinida_selecionada
        },
        assunto(state) {
            return state.assunto
        },
        emailPara(state) {
            return state.email_para
        },
        emailCc(state) {
            return state.email_cc
        },
        emailAnexo(state) {
            return state.email_anexo
        },
    },

    mutations: {
        SET_ANEXOS_EMAIL (state, anexos) {
            state.anexos_email = anexos
        },
        SET_ANEXOS_PARA_ENVIAR (state, anexos) {
            state.anexos_para_enviar = anexos
        },
        PUSH_ANEXOS_PARA_ENVIAR (state, anexo) {
            if(!state.anexos_para_enviar) {
                state.anexos_para_enviar = [anexo]
                return
            }
            state.anexos_para_enviar = [...state.anexos_para_enviar, anexo]
        },
        SET_MENSAGENS_PREDEFINIDAS (state, msgsPre) {
            state.mensagens_predefinidas = msgsPre
        },
        SET_MENSAGEM_PREDEFINIDA_SELECIONADA (state, msgPre) {
            state.mensagem_predefinida_selecionada = msgPre
        },
        SET_ASSUNTO (state, assunto) {
            state.assunto = assunto
        },
        SET_EMAIL_PARA (state, email) {
            state.email_para = email
        },
        SET_EMAIL_CC (state, email) {
            state.email_cc = email
        },
        SET_EMAIL_ANEXO(state, email) {
            state.email_anexo = email
        },
    },

    actions: {
        async buscaAnexosEmail({ commit }, idAtendimento) {
            try {
                const anexos = await AtendimentoServices.buscarAnexos(idAtendimento)
                commit('SET_ANEXOS_EMAIL', anexos)
            } catch(e) {
                console.log(e)
            }
        },

        async buscaMensagensPredefinidas({ commit }) {
            try {
                const msgs = await ModuloEmailServices.buscaMensagensDisponiveis()
                commit('SET_MENSAGENS_PREDEFINIDAS', msgs)
            } catch(e) {
                console.log(e)
                commit('SET_MENSAGENS_PREDEFINIDAS', null)
            }
        },

        selecionaMensagemPredefinida({ commit }, msg) {
            commit('SET_MENSAGEM_PREDEFINIDA_SELECIONADA', msg)
        },

        atualizarDadosEmail({ commit }, dados) {
            commit('SET_ASSUNTO', dados?.assunto || '')
            commit('SET_EMAIL_PARA', dados?.emailPara || '')
            commit('SET_EMAIL_CC', dados?.emailCc || '')
            commit('SET_EMAIL_ANEXO', dados?.emailAnexo || '')
        },

        async salvarAnexo({ commit }, arquivo) {
            try {
                let nomeArquivo = await AtendimentoEmailServices.salvarArquivosEmail(arquivo)
                commit('PUSH_ANEXOS_PARA_ENVIAR', {
                    nomeSalvo: nomeArquivo,
                    nomeOriginal: arquivo.name
                })
            } catch(e) {
                console.log('Ocorreu um erro ao salvar o arquivo de anexo', e)
            }
        },
        limpaAnexos({ commit }) {
            commit('SET_ANEXOS_PARA_ENVIAR', null)
        }

    },
}
