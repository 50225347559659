import axios from 'axios'
const URL_API = '/api'

const getTabulacaoBasico = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/Tabulacao/getTabulacaoBasico`)
        return data
    } catch (e) {
        console.log('erro getTabulacaoBasico', e)
        throw (e)
    }
}

const getItensTabulacoes = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/TabulacaoItem`)
        return data
    } catch (e) {
        console.log('erro getItensTabulacao', e)
        throw (e)
    }
}

const getItensByTabulacaoId = async (tabulacaoId) => {
    try {
        const { data } = await axios.get(`${URL_API}/TabulacaoItem/GetByTabulacao/${tabulacaoId}`)
        return data
    } catch (e) {
        console.log('erro getItensByTabulacaoId', e)
        throw(e)
    }
}

const adicionar = async (tabulacaoItem) => {
    try {
        delete tabulacaoItem.Id;
        delete tabulacaoItem.EmpresaId;
        const response = await axios.post(`${URL_API}/TabulacaoItem`, tabulacaoItem)
        return response ? response.data : null
    } catch (e) {
        console.log('erro adicionarItemTabulacao', e)
        throw (e)
    }
}

const editar = async (tabulacaoItem) => {
    try {
        const response = await axios.put(`${URL_API}/TabulacaoItem`, tabulacaoItem)
        return response ? response.data : null
    } catch (e) {
        console.log('erro editarItemTabulacao', e)
        throw (e)
    }
}

const remover = async (tabulacaoId) => {
    try {
        const response = await axios.delete(`${URL_API}/TabulacaoItem/${tabulacaoId}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removerItemTabulacao', e)
        throw (e)
    }
}
const buscaKanbansTabulacoes = async () => {
    try {
        const response = await axios.get(`${URL_API}/Tabulacao/BuscaKanbansTabulacoes`,)
        return response ? response.data : null
    } catch (e) {
        console.log('erro BuscaKanbansTabulacoes', e)
        throw (e)
    }
}

export default {
    getItensTabulacoes,
    getItensByTabulacaoId,
    adicionar,
    editar,
    remover,
    buscaKanbansTabulacoes,
    getTabulacaoBasico
}
