import UsuarioSetoresServices from '@/assets/js/services/UsuarioSetorServices'

export default {
    namespaced: true,

    state: {
        lista_usuarios_setores: null,
        lista_prioridades: null,
    },

    getters: {
        listaUsuarioSetores(state) {
            return state.lista_usuarios_setores;
        },
        listaPrioridades(state) {
            return state.lista_prioridades;
        }
    },

    mutations: {
        SET_USUARIO_SETORES(state, usuariosSetores) {
            state.lista_usuarios_setores = usuariosSetores
        },
        SET_PRIORIDADES(state, prioridades) {
            state.lista_prioridades = prioridades;
        },
    },

    actions: {
        async buscaUsuarioSetores({ commit, dispatch }) {
            try {
                const usuarioSetores = await UsuarioSetoresServices.getUsuarioSetores()
                commit('SET_USUARIO_SETORES', usuarioSetores)
                dispatch('buscaPrioridades')
                return
            } catch (e) {
                console.log(e)
                return
            }
        },
        async adicionar({ dispatch }, usuarioSetor) {
            delete usuarioSetor.Id
            const usetor = await UsuarioSetoresServices.postUsuarioSetor(usuarioSetor)
            console.log(usetor)
            dispatch('buscaUsuarioSetores')
        },
        async editar({ dispatch }, usuarioSetor) {
            const usetor = await UsuarioSetoresServices.putUsuarioSetor(usuarioSetor)
            console.log(usetor)
            dispatch('buscaUsuarioSetores')
        },
        async remover({ dispatch }, id) {
            try {
                const usuariosetor = await UsuarioSetoresServices.removeUsuarioSetor(id)
                console.log(usuariosetor)
                dispatch('buscaUsuarioSetores')
            } catch (e) {
                console.log(e);
            }
        },
        async buscaPrioridades({ commit }) {
            try {
                const prioridades = await UsuarioSetoresServices.getPrioridades()
                commit('SET_PRIORIDADES', prioridades)
            } catch (e) {
                console.log(e);
            }
        }
    },
}