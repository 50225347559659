import axios from 'axios'
const URL_API = '/api'

const getListaPerguntas = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/chatPergunta/GetLista`)
        return data
    } catch (e) {
        console.log('erro getListaChatPerguntas', e)
        throw (e)
    }
}

export default {
    getListaPerguntas
}