import axios from 'axios'
const URL_API = '/api'

const getTabulacoes = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/tabulacao/tabulacoes`)
        return data
    } catch (e) {
        console.log('erro getTabulacoes', e)
        throw (e)
    }
}

const adicionar = async (tabulacao) => {
    try {
        delete tabulacao.Id;
        delete tabulacao.EmpresaId;
        const response = await axios.post(`${URL_API}/tabulacao`, tabulacao)
        return response ? response.data : null
    } catch (e) {
        console.log('erro adicionarTabulacao', e)
        throw (e)
    }
}

const editar = async (tabulacao) => {
    try {
        const response = await axios.put(`${URL_API}/tabulacao`, tabulacao)
        return response ? response.data : null
    } catch (e) {
        console.log('erro editarTabulacao', e)
        throw (e)
    }
}

const remover = async (id) => {
    try {
        const response = await axios.delete(`${URL_API}/tabulacao/${id}`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro removerTabulacao', e)
        throw (e)
    }
}

const getTabulacoesPai = async () => {
    try {
        const response = await axios.get(`${URL_API}/tabulacao/pai`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getTabulacaoPai', e)
        throw (e)
    }
}

const getEnumTipos = async () => {
    try {
        const response = await axios.get(`${URL_API}/tabulacao/tipos`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getEnumTipos', e)
        throw (e)
    }
}

const getEnumTiposClientes = async () => {
    try {
        const response = await axios.get(`${URL_API}/tabulacao/tipos-clientes`)
        return response ? response.data : null
    } catch (e) {
        console.log('erro getEnumTiposClientes', e)
        throw (e)
    }
}

export default {
    getTabulacoes,
    adicionar,
    editar,
    remover,
    getTabulacoesPai,
    getEnumTipos,
    getEnumTiposClientes
}